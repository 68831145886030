import React from 'react';
import {  BrowserRouter as Router,  Switch,  Route } from "react-router-dom";
import routes from "./routes"

const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>Loading...</div>
);

function App() {
  return (
   <Router>
      <React.Suspense fallback={ loading }>
        <Switch>
            { routes.map((route, idx) => {
             
                  return route.component ? (
                      <Route key={ idx } path={ route.path } exact={ route.exact } name={ route.name } render={ props => <route.component name={route.name} { ...props } /> } />
                      ) : null;
              })
            }        
        </Switch>
    </React.Suspense>
   </Router>
  );
}

export default App;
