import React from 'react';

const home = React.lazy(() => import('./pages/home'));
const Aboutus = React.lazy(() => import('./pages/aboutus'));
const Services = React.lazy(() => import('./pages/services'));
const OurWork = React.lazy(() => import('./pages/our-work'));
const Technologies = React.lazy(() => import('./pages/technologies'));
const Industries = React.lazy(() => import('./pages/industries'));
const Careers = React.lazy(() => import('./pages/careers'));
const TC = React.lazy(() => import('./pages/TermsCondition'));
const Blogs = React.lazy(() => import('./pages/blogs'));
const BlogSingle = React.lazy(() => import('./pages/BlogSingle'));
const ContactUs = React.lazy(() => import('./pages/contact-us'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacy-policy'));
const Glenmark = React.lazy(() => import('./pages/caseStudy/Glenmark'))
const Ivoomi = React.lazy(() => import('./pages/caseStudy/Ivommi'))
const MahaDhan = React.lazy(() => import('./pages/caseStudy/MahaDhan'))
const Mark = React.lazy(() => import('./pages/caseStudy/Mark'))
const Swami = React.lazy(() => import('./pages/caseStudy/Swami'))
const RoyalPurandar = React.lazy(() => import('./pages/caseStudy/RoyalPurandar'))

const routes = [
    {
        exact: true,
        component: home,
        name: 'Home',
        path: '/'
    },
    {
        exact: true,
        component: Aboutus,
        name: 'About Us',
        path: '/about-us'
    },
    {
        exact: true,
        component: Services,
        name: 'Services',
        path: '/services'
    },
    {
        exact: true,
        component: OurWork,
        name: 'Our Work',
        path: '/our-work'
    },
    {
        exact: true,
        component: Technologies,
        name: 'Technologies',
        path: '/technologies'
    },
    {
        exact: true,
        component: Industries,
        name: 'Industries',
        path: '/industries'
    },
    {
        exact: true,
        component: Careers,
        name: 'Careers',
        path: '/careers'
    },
    {
        exact: true,
        component: Blogs,
        name: 'Blogs',
        path: '/blogs'
    },
    {
        component: BlogSingle,
        name: 'Blog Single',
        path: "/blog/:id"
    },
    {
        exact: true,
        component: ContactUs,
        name: 'Contact Us',
        path: '/contact-us'
    },
    {
        exact: true,
        component: PrivacyPolicy,
        name: 'Privacy & Policy',
        path: '/privacy-policy'
    },
    {
        exact: true,
        component: TC,
        name: 'Terms of Service',
        path: '/terms-of-service'
    },
    {
        exact: false,
        component: Services,
        name: 'Design Experience',
        path: '/services/design-experience'
    },
    {
        exact: false,
        component: Services,
        name: 'Digital Marketing',
        path: '/services/digital-marketing'
    },
    {
        exact: false,
        component: Services,
        name: 'Digital Strategy',
        path: '/services/digital-strategy'
    },
    {
        exact: false,
        component: Services,
        name: 'Digital Transformation',
        path: '/services/digital-transformation'
    },
    {
        exact: false,
        component: Services,
        name: 'Planing Analytics',
        path: '/services/planing-analytics'
    },

    {
        exact: true,
        component: Industries,
        name: 'FMCG Marketing',
        path: '/industries/fmcg-marketing'
    },
    {
        exact: true,
        component: Industries,
        name: 'Real Estate Marketing',
        path: '/industries/real-estate-marketing'
    },
    {
        exact: true,
        component: Industries,
        name: 'Pharmaceutical Marketing',
        path: '/industries/pharmaceutical-marketing'
    },
    {
        exact: true,
        component: Industries,
        name: 'Healthcare Marketing',
        path: '/industries/healthcare-marketing'
    },
    {
        exact: true,
        component: Industries,
        name: 'Manufacturing Companies Marketing',
        path: '/industries/manufacturing-companies-marketing'
    },

    {
        exact: false,
        component: Mark,
        name: 'Mark Case Study',
        path: '/case-study/mark'
    },

    {
        exact: false,
        component: Glenmark,
        name: 'Gorilla Case Study',
        path: '/case-study/glenmark'
    },
    {
        exact: false,
        component: Ivoomi,
        name: 'Ivoomi Case Study',
        path: '/case-study/ivoomi'
    },
    {
        exact: false,
        component: RoyalPurandar,
        name: 'RoyalPurandar Case Study',
        path: '/case-study/royalpurandar'
    },
    {
        exact: false,
        component: MahaDhan,
        name: 'MahaDhan Case Study',
        path: '/case-study/mahadhan'
    },
    {
        exact: false,
        component: Swami,
        name: 'Swami Narayan City',
        path: '/case-study/swaminarayancity'
    }



]
export default routes;
